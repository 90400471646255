import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.article`
  width: 254px;
  background: ${({ theme }) => theme.bg.primary};
  border-radius: 3px;
  padding: 30px;
  margin-right: 1rem;
  box-shadow: 0px 3px 6px #00000029;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: none;

  &.itemCardTransparent {
    opacity: 0;
    width: 8%;

    ${mediaQuery.desktop`
      width: 376px
    `}
  }
  
  ${mediaQuery.desktop`
    width: 376px;
    padding: 40px;
  `}

  &:first-child {
    margin-left: 10%;
  }

  h3 {
    font: 18px 'Poppins', sans-serif;
    font-weight: 600;
    color: ${({ theme }) => theme.font.second};
    margin-bottom: 0;

    ${mediaQuery.desktop`
      font-size: 24px;
      margin-bottom: 20px;
    `}

  }

  p {
    font: 16px 'Poppins', sans-serif;
    font-weight: 400;
    color: ${({ theme }) => theme.font.primary};
  }

  i {
    margin-bottom: 10px;
  }

  &.noBefore {
    &:before {
      display: none;
    }
  }

  ::before {
    content: '';
    display: block;
    width: 40px;
    height: 5px;
    background: ${({ theme }) => theme.bg.fourth}!important;
    margin-bottom: 17px;
  }
`;
