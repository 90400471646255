import React from "react";

import { Container } from "./styles";

import JumbotronSmaller from "../Jumbotron-smaller";
import Button from "../Button";

import { SliderProps } from "./interface";

const Slider = ({
  classContainer,
  classItem,
  activeBtnClass,
  arrowColor,
  items,
  headers,
}: SliderProps) => {
  const [withItemCard, setWithItemCard] = React.useState(0);
  const [contentCards, setContentCards] = React.useState(items[0].content);
  const [active, setActive] = React.useState(0);
  const [arrowLeft, setArrowLeft] = React.useState(false);
  const [arrowRight, setArrowRight] = React.useState(true);

  const handleScroll = React.useCallback(
    (position: number): void => {
      const scrolbar = document.querySelectorAll(".scroll-row");

      scrolbar[0].scrollLeft += position;
    },
    [withItemCard]
  );
 



  React.useEffect(() => {
    const card = document.querySelector(".itemCard").clientWidth;

    setWithItemCard(card);
  }, []);

  function handleSelection(i) {
    setContentCards(items[i].content);
    setActive(i);
  }

  function handleRigth() {
    const scrolableDiv = document.querySelector(".scrolable").clientWidth;
    const scrolbar = document.querySelectorAll(".scroll-row");
    setArrowLeft(true);
    
    handleScroll(withItemCard + 16)
      
    const offsetFinal = (scrolableDiv / 10) * 4 ;
    if(scrolbar[0].scrollLeft > offsetFinal ) {
      console.log('final');
      setArrowRight(false);
    }
  }

  function handleLeft() {
    const scrolableDiv = document.querySelector(".scrolable").clientWidth ;
    const scrolbar = document.querySelectorAll(".scroll-row");
    setArrowRight(true);

    const offsetInital = (scrolableDiv / 10) * 3 ;

    handleScroll(-withItemCard - 16);


    if(scrolbar[0].scrollLeft < offsetInital ) {
      console.log('inicio');
      setArrowLeft(false);
    }
  }

  return (
    <Container arrowColor={arrowColor}>
      {headers && (
        <div className="slider__headers">
          {headers.map((header, i) => (
            <Button
              className={`slider__headersBtn ${
                active === i ? activeBtnClass : ""
              }`}
              key={header.btnText}
              type="button"
              hiddenIcon={false}
              onClick={() => handleSelection(i)}
            >
              {header.btnText}

            </Button>
          ))}
        </div>
      )}
      <div className={`scroll-row ${classContainer}`}>
        <div className="scrolable">
          {contentCards.map((item) => (
            <JumbotronSmaller
              className={`itemCard ${classItem}`}
              key={item.id}
              title={item.title}
              text={item.text}
            />
          ))}

          {/* s2 */}
          <JumbotronSmaller
              className={`itemCard itemCardTransparent ${classItem}`}
              key='456'
              title=''
              text=''
            />
        </div>
      </div>
      <div className="btn__content">
        <button
          className={`slider__btn rollback ${arrowLeft ? '': 'opacity' }`}
          onClick={() => handleLeft()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <g transform="translate(0 50)">
              <rect
                className="a"
                width="30"
                height="30"
                transform="translate(0 -50)"
              />
              <path
                className="b"
                d="M14.053,4,12.294,5.759,19.331,12.8H4V15.31H19.331l-7.037,7.037,1.759,1.759L24.107,14.053Z"
                transform="translate(0.733 -49.267)"
              />
            </g>
          </svg>
        </button>
        <button
          className={`slider__btn ${arrowRight ? '': 'opacity' }`}
          onClick={() =>  handleRigth()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <g transform="translate(0 50)">
              <rect
                className="a"
                width="30"
                height="30"
                transform="translate(0 -50)"
              />
              <path
                className="b"
                d="M14.053,4,12.294,5.759,19.331,12.8H4V15.31H19.331l-7.037,7.037,1.759,1.759L24.107,14.053Z"
                transform="translate(0.733 -49.267)"
              />
            </g>
          </svg>
        </button>
      </div>
    </Container>
  );
};

export default Slider;
