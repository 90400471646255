import React from "react";

import { Container } from "./styles";

import { CardProps } from "./interface";

const Card = ({
  image,
  altImage,
  imageMobile,
  orientation,
  title,
  text,
  children,
}: CardProps) => {
  return (
    <Container id={title} className={orientation}>
      <div className="container">
        <div className="text-wrapper">
          <h1>{title}</h1>
          <p>{children ? children : text}</p>
        </div>
        <img src={image} alt={altImage} />
      </div>
    </Container>
  );
};

export default Card;
