export default {
  cards: [
    {
      title: "Crédito e Cobrança",
      text:
        "Apoio no desenvolvimento das políticas de crédito e cobrança para as empresas conveniadas e funcionários. Além da disponibilidade de ferramentas para análise de crédito e estudo de viabilidade comercial dos convênios.",
    },
    {
      title: "Motor de Crédito",
      text:
        "Análise de forma rápida e automática dos seus parâmetros de crédito.",
    },
    {
      title: "Servicing",
      list: [
        { text: "Gestão de limite de crédito por convênio" },
        {
          text: "Esteira de crédito com consulta a dados em bureau de crédito",
        },
        { text: "Atendimento Multiplataforma" },
        { text: "Sistema para gerenciar contratos, faturas e integrar bases" },
        { text: "Conferência de margem e documentos" },
      ],
    },
    {
      title: "Gestão de Carteira de Crédito",
      list: [
        {
          text:
            "Controle da cessão, baixas da carteira e conciliação, incluindo apropriação diária da receita e PDD",
        },
        {
          text:
            "Fazemos a cobrança e a conciliação das parcelas descontadas dos funcionários junto às empresas conveniadas, e também em casos de inadimplência",
        },
        { text: "Atendimento Multiplataforma" },
      ],
    },
    {
      title: "Opção White Label",
      text: "Sua marca em todas as etapas do processo.",
    },
  ],
  cardsSliderItems: [
    {
      content: [
        {
          id: 0,
          title: "Motor de Decisão",
          text:
            "A partir do retorno das consultas configuráveis com sua política de crédito, essa função proporciona maior segurança à sua tomada de decisão.",
        },
        {
          id: 1,
          title: "Servicing",
          text:
            "Auxílio no processo de aprovação cadastral, verificação de Compliance e validação de firmas e poderes.",
        },
        {
          id: 2,
          title: "Gestão de Carteira de Crédito",
          text:
            "Acompanhamento do processo de cobrança, conciliação, além da visão de indicadores.",
        },
        {
          id: 3,
          title: "Opção White Label",
          text: "Sua marca em todas as etapas do processo.",
        },
      ],
    },
  ],
  howWork: [
    {
      text: "Onboarding da Documentação Societária e Financeira",
    },
    {
      text:
        "Consulta automática dos parâmetros de crédito: Serasa, Bacen e SRF - Secretaria da Receita Federal",
    },
    {
      text: "Simulação do cálculo da operação",
    },
    {
      text:
        "Captura de documentos das pessoas físicas e jurídicas envolvidas na operação",
    },
    {
      text: "Disponibilização das informações para análise de risco",
    },
    {
      text: "Liquidação da operação",
    },
    {
      text: "Cessão de crédito",
    },
  ],
};
