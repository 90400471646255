import React from "react";

import { Container } from "./styles";

import { JumbotronProps } from "./interface";

const JumbotronSmaller = ({
  icon,
  title,
  text,
  className,
  children,
}: JumbotronProps) => {
  return (
    <Container className={className}>
      <i>{icon}</i>
      <h3>{title}</h3>
      <p>{text}</p>
      {children}
    </Container>
  );
};

export default JumbotronSmaller;
