import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.article`
  background: ${({ theme }) => theme.bg.primary};
  border-radius: 3px;
  padding: 80px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h2 {
    font: 31px 'Poppins', sans-serif;
    font-weight: 600;

    color: ${({ theme }) => theme.font.second};
    margin-bottom: 20px;
    line-height: 40px;

    ${mediaQuery.desktop`
      font-size: 45px;
      line-height: 54px;
    `}
  }

  p {
    font: 16px 'Poppins', sans-serif;
    font-weight: 600;
    letter-spacing: 0.54px;
    color: ${({ theme }) => theme.font.primary};
    letter-spacing: 0.48px;

    ${mediaQuery.desktop`
      letter-spacing: inherit;
      font-weight: inherit;
    `}
  }

  ::before {
    content: '';
    margin-bottom: 15px;
    display: block;
    width: 40px;
    height: 5px;
    background: ${({ theme }) => theme.bg.fourth};
  }
`;
