import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.main`
  .section-multifunctional {
    background-color: ${({ theme }) => theme.bg.second};
    padding-bottom: 80px;
    margin-top: 80px;

    overflow: hidden;

    ${mediaQuery.desktop`
      margin-top: 150px;
      padding-bottom: 80px;
    `}

    .title {
        background-color: ${({ theme }) => theme.bg.second};
        margin: none;
        padding: none;

      h2 {
        color: ${({ theme }) => theme.font.white};
      }

      p {
        color: ${({ theme }) => theme.font.white};
      }
    }
  }

  .container.CreditPj__next-page {  
    justify-content: center;
    padding-top: 63px;

    ${mediaQuery.desktop`
       display: flex;
       padding: 80px 0 150px;
    `}

    button {
      font-weight: 600;
      text-transform: uppercase;
      min-height: 53px;
      width: 100%;
      margin-bottom: 80px;

      ${mediaQuery.desktop`
       display: flex;
       width: auto;
       margin-bottom: 0px;
      `}
    }
  }
`;
