import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';


export const Container = styled.article`
  margin-top: 80px;
  position: relative;
  display: flex;

  ${mediaQuery.desktop`
    margin-top: 150px;
    height: 462px;
    position: relative;
    display: flex;
  `}

  img {
    width: 100%;

    ${mediaQuery.desktop`
      position: absolute;
      width: auto;
      width: 874px;
    `}
  }

  li img {
    width: auto;
  }

  .container {
    ${mediaQuery.desktop`
        display: flex;
        position: relative;
    `}
  }

  &.right {
    
    .container {
      justify-content: flex-end;
    }
    
    img {
      left: -308px;
    }
  }

  &.left {
    
    .container {
      justify-content: flex-start;
    }
    
    img {
      right: -308px;
    }
  }

  .text-wrapper {
    display: inline-flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    margin-bottom: 50px;

    ${mediaQuery.desktop`
      width: 575px;
      text-align: left;
      margin: 0px;
    `}

    h1 {
      font-size: 26px;
      color: #333;
      margin-bottom: 20px;
      font-weight: 600;

      ${mediaQuery.desktop`
        font-size: 40px;
        margin-bottom: 20px;
      `}
    }

    p {
      font-size: 14px;
      color: #757474;

      ${mediaQuery.desktop`
        font-size: 24px;
        margin-bottom: 20px;
      `}
    }
  }
`;
