import React from "react";
import { navigate } from "gatsby";

import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import Button from "../../components/Button";
import FullContentWithImage from "../../components/fullContentWithImage";
import TitleSection from "../../components/TitleSection";
import Slider from "../../components/Slider";
import NumericList from "../../components/Numeric-list";

import { Container } from "./styles";

import bgCreditPj from "../../assets/images/bg_credit-pj.jpg";
import bgCreditPjMobile from "../../assets/images/bg_credit-pj-mobile.jpg";
import laptop_pj_1 from "../../assets/images/laptop_pj-1.png";
import laptop_pj_2 from "../../assets/images/laptop_pj-2.png";

import { useWindowSize } from "../../hooks/resizeWindow";

import contentCreditPjAsAService from "../../content/pages/CreditPjAsAService";

const CreditPjAsAService = () => {
  const size = useWindowSize();

  React.useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
  }, [])

  function handleNextPage() {
    navigate("/contato");
  }

  return (
    <Layout>
      <Container>
        <Banner
          title="Crédito PJ as a Service"
          text="Solução completa, fácil e totalmente digital que padroniza e realiza processos de concessão de crédito para pessoas jurídicas com rapidez e segurança."
          backgroundImg={size.width > 800 ? bgCreditPj : bgCreditPjMobile}
          classJumbotron="info-banner col-md-7"
        />
        <FullContentWithImage
          image={laptop_pj_1}
          altImage="Laptop com tela do sistema de Crédito PJ Zipdin"
          orientation="right"
          title="Completa"
          text="A Plataforma de Crédito PJ ajuda a sua empresa nos processos de originação, análise e formalização de operações de crédito para pessoas jurídicas, com garantias em aval, ativos financeiros, recebíveis e imóveis."
        />
        <FullContentWithImage
          image={laptop_pj_2}
          altImage="Laptop com tela do sistema de Crédito PJ Zipdin"
          orientation="left"
          title="Prática"
          text="É prática e totalmente digital. Executa o onboarding das documentações societárias e financeiras, com a emissão e assinatura da CCB, e permite o acompanhamento online de toda a operação pelo computador, tablet ou celular."
        />
        <section id="multifunctional" className="section-multifunctional">
          <TitleSection
            className="title"
            title="Multifuncional"
            text="A Plataforma de Crédito PJ oferece funcionalidades importantes para ajudar ainda mais a sua empresa nas operações de crédito."
          />
          <Slider
            items={contentCreditPjAsAService.cardsSliderItems}
            arrowColor="#fff"
          />
        </section>
        <section id="section-how-works">
          <TitleSection
            className="title"
            title="Como funciona?"
            text="Veja como a Plataforma de Crédito PJ realiza todas as etapas do processo de concessão de crédito."
          />
          <NumericList item={contentCreditPjAsAService.howWork} />
        </section>
        <section className="container CreditPj__next-page">
          <Button onClick={handleNextPage}>Fale conosco</Button>
        </section>
      </Container>
    </Layout>
  );
};

export default CreditPjAsAService;
