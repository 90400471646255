import React from 'react';

import { Container } from './styles';

import { JumbotronProps } from './interface';

const Jumbotron: React.FC<JumbotronProps> = ({
  title,
  text,
  className,
  children,
}) => {
  return (
    <Container className={className}>
      <h2>{title}</h2>
      <p>{text}</p>
      {children}
    </Container>
  );
};

export default Jumbotron;
